/*

Login
Logout
Signup
Register
Forgot password
Resend verification

*/

import React, {createContext, useEffect, useReducer, useState} from 'react';



// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import {
    ACCOUNT_INITIALIZE,
    LOGIN,
    LOGOUT,
    SEND_VERIFY,
    SEND_RESET,
    DO_RESET,
    FAIL_RESET,
    CLEAR_RESET,
    CLEAR_VERIFY,
    UPDATE_COGNITO_ATTRIBUTES
} from '../store/actions';
import accountReducer from '../store/accountReducer';

// project imports
import axios from 'axios';

import Loader from '../ui-component/Loader';
import config from '../config'; 
import moment from 'moment';
import {AgreementUserTypes, UsersService} from "../api/connect";
import {HubConnectionBuilder} from "@microsoft/signalr";

import { Analytics, sendAnalytics } from '../utils';
import { signOut } from '../views/authentication/amplify/Amplify';
import {PhoneNumberFormat as PNF} from "google-libphonenumber";

// phone number validation
// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
/*============ Configure axios  =============*/

const axiosAuth = axios.create({
    baseURL : config.awsAuth.baseUrl,
    headers: {[config.awsAuth.apiKeyPrefix]: config.awsAuth.xApiKey}
});

const refreshTokens = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
        try {
            const data = {
                RefreshToken : localStorage.getItem('refreshToken')
            }
            const response = await axiosAuth.post('/refreshtoken', data);
            if (response.data.successful) {
                setStorage(response.data);
                return true;
            } else {
                setStorage(null);
                return false;
            }
        } catch (err) {
            console.log(err);
            setStorage(null);
            return false;
        }
        
    }
    
    return false;
}






/*============ Storage, verify, header, error functions =============*/

// constant
const initialState = {
    isInitialized: false,
    sentVerify : false,
    sentReset : false, // sent the email with code
    didReset : false, // changed the password ,
    failedReset : false,
    isLoggedIn: false,
    isRegistered : false,
    cognitoSub : null,
    user : null
};

const getJwtHeader = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[0]));
  } catch (e) {
    return null;
  }
};

export const verifyToken = (token) => {
    if (!token) {
        return false;
    }

    const jwtHeader = getJwtHeader(token);

    const jwksKeys = config.awsAuth.jwks.keys;
    
    let obj = jwksKeys.find(key => key.kid === jwtHeader.kid);

    if (obj === undefined) {
        return false;
    }

    const decoded = jwtDecode(token);

    return !(decoded.iss !== config.awsAuth.iss || decoded.aud !== config.awsAuth.aud);

};

const getCognitoSub = (token) => {
    const decoded = jwtDecode(token);
    return decoded.sub;
}

const getCountryCode = (token) => {
    const decoded = jwtDecode(token);
    return decoded['locale']
}

const getPhone = (token) => {
    const decoded = jwtDecode(token);
    return decoded['phone_number']
}

const getFirstName = (token) => {
    const decoded = jwtDecode(token);
    return decoded.given_name;
}

const getLastName = (token) => {
    const decoded = jwtDecode(token);
    return decoded.family_name;
}

const getEmail = (token) => {
  const decoded = jwtDecode(token);
  return decoded.email;
}

const hasGoogleIdentity = (token) => {
    const decoded = jwtDecode(token);
    const identities = decoded['identities'];
    return Array.isArray(identities) && identities.some(identity => identity.providerType === 'Google');
}

const setStorage = (data) => {
    if (data) {
        localStorage.setItem('accessToken', data.results.authenticationResult.accessToken);
        localStorage.setItem('idToken', data.results.authenticationResult.idToken);
        if (data.results.authenticationResult.refreshToken) {
          localStorage.setItem('refreshToken',data.results.authenticationResult.refreshToken);
        }

    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
    }
}



function AuthError(message, code)  {
    this.message = message;
    this.code = code;
    this.name = 'AuthError';
}

//-----------------------|| Auth CONTEXT & PROVIDER ||-----------------------//

const AuthContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    register: () => Promise.resolve(),
    updateUser: () => Promise.resolve(),
    updatePatient: (data) => Promise.resolve(),
    resendVerification: () => Promise.resolve(),
    sendReset: () => Promise.resolve(),
    doReset: () => Promise.resolve(),
    checkEmailExists : () => Promise.resolve(),
    checkPhoneExists : (p) => Promise.resolve(),
    updateCognitoAttributes: (u) => Promise.resolve(),
    isGoogleAccount:()  => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const login = async (email, password, emailRegister = null, trialValueId = null) => {
        const data = {
          "username":email,
          "password":password
        };

        try {
            const response = await axiosAuth.post('/login', data);
        
            if (response.data.successful === true) {

                const idToken = response.data.results.authenticationResult.idToken;

                if (verifyToken(idToken)) {

                    setStorage(response.data);

                    const cognitoSub = getCognitoSub(idToken);
                
                    const user = await UsersService.getApiUsersGetUserBySub(cognitoSub);
                    
                    if (user.successful) {
                        if (trialValueId && email === emailRegister) {
                            const trialId = parseInt(trialValueId);
                            await UsersService.putApiUsersAutoRegisterPatientToTrial({
                                trialId,
                                userCognitoSub : user.results.cognitoSub,
                                authorCognitoSub : user.results.cognitoSub
                            });
                        }
                        sendAnalytics({
                          key: Analytics.SignIn,
                          role: user.results?.role,
                          company: user.results?.company?.name
                        });
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                cognitoSub : cognitoSub,
                                isRegistered : true,
                                user : {
                                    ...user.results,
                                    isGoogleAccount: hasGoogleIdentity(idToken)
                                }
                            }
                        });

                    } else {
                        sendAnalytics({ key: Analytics.SignInFail });
                        dispatch({
                            type: LOGIN,
                            payload: {
                                cognitoSub : cognitoSub,
                                isRegistered : false,
                                isLoggedIn: true,
                                user : {
                                    email : email,
                                    cognitoSub : cognitoSub,
                                    firstName : getFirstName(idToken),
                                    lastName : getLastName(idToken),
                                    countryCode: getCountryCode(idToken),
                                    phone: getPhone(idToken),
                                    isGoogleAccount: hasGoogleIdentity(idToken)
                                }
                            }
                        });
                    }

                } else {
                    throw new AuthError('Invalid tokens');
                }

                

            } else {
                throw new AuthError(response.data.responseMessage);
            }

        } catch (error) {
            if (error.response) {
                sendAnalytics({ key: Analytics.SignInFail });
                var msg, code = '';
                console.log(error.response.data.failureType);
                switch(error.response.data.failureType) {
                    case 'unconfirmed': {
                        msg = 'You need to verify your email address before you can login.';
                        code = 'unconfirmed';
                        break;
                    }
                        
                    case 'no_user': {
                        msg = 'No account exists for that email address.';
                        break;
                    }
                        
                    default: {
                        msg = error.response.data.responseMessage;
                        break;
                    }
                }
                throw new AuthError(msg, code);
            } else {
                throw new AuthError('Unable to log you in at this time.');
            }
            

        }
         
            
    
       
    };

    const logout = async () => {
        const data = {
          RefreshToken: window.localStorage.getItem('refreshToken')
        };
        try {
            await axiosAuth.post('/revokerefreshtoken', data);
            await signOut();
        } catch {

        } finally {
            setStorage(null);
            dispatch({ type: LOGOUT, payload:{ isLoggedIn: false } });
            window.location.reload();

        }
        
        
    };

    const signup = async (email, password, firstname, lastname, mobile, country) => {
        const data = {
          "username":email,
          "password":password,
          "givenname":firstname,
          "familyname":lastname,
          "mobilePhoneNumber": mobile,
          "countrycode": country,
        };
        try {
            const response = await axiosAuth.post('/signup', data);
            if (response.data.successful === true) {
                dispatch({
                    type: SEND_VERIFY
                });
            } else {
                throw new AuthError('Unable to sign you up at this time.');
            }

        } catch (error) {
            if (error.response) {
                var msg, code = '';
                switch(error.response.data.failureType) {
                    case 'username_exists': {
                        msg = 'An account with that email address already exists.';
                        code = 'username_exists';
                        break;
                    }
                        
                    default: {
                        msg = error.response.data.responseMessage;
                        break;
                    }
                }
                throw new AuthError(msg, code);
            } else {
                throw new AuthError('Unable to sign you up at this time.');
            }
        }
         
    }

    const register = async (form) => {

        const formattedDob = moment(form.dob,'yyyy-MM-DD').format('yyyy-MM-DDT00:00:00');
        try {
            const number = phoneUtil.parse( form.phone, form.country);
            await updateCognitoAttributes({
                email: state.user.email,
                firstName: state.user.firstName,
                lastName: state.user.lastName,
                isGoogleAccount: state.user.isGoogleAccount,
                phone: phoneUtil.format(number, PNF.E164),
                countryCode: form.country
            });

            const privacy = await UsersService.getApiUsersGetUserAgreementDocument(0, AgreementUserTypes._0);
            const terms = await UsersService.getApiUsersGetUserAgreementDocument(1, AgreementUserTypes._0);
            const user = await UsersService.postApiUsersCreateUser(true, true,{
                userType: "Patient",
                userData: {
                    "dob": formattedDob, //"0001-01-01T00:00:00",
                    "sex": form.sex, // 0 = M, 1 = F, 2 = Other
                    "location": form.location ,
                    "postCode": form.postcode ,
                    "phoneNumber": phoneUtil.format(number, PNF.E164),
                    "cognitoSub": state.user.cognitoSub,
                    "email": state.user.email,
                    "firstName": state.user.firstName,
                    "lastName": state.user.lastName,
                    "countryCode" : form.country,
                    "state" : form.state ? form.state : form.country,
                },
                privacyPolicyAgreement: { "initialVersion" : privacy?.results?.versionNumber },
                termsOfServiceAgreement: { "initialVersion" : terms?.results?.versionNumber },
            });
            if (user.successful) {

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        cognitoSub : user.results.cognitoSub,
                        isRegistered : true,
                        user : user.results,
                    }
                });
                
            } else {
                throw new AuthError('Unable to update your details at this time.');
            }
        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to update your details at this time.');
            }
        }
        
    };

    const updatePatient = async(data) => {

        if (data.hasOwnProperty('dob')) {
            data.dob = moment(data.dob,'yyyy-MM-DD').format();
        }
        try {
            const user = await UsersService.putApiUsersUpdateUser(data);
            if (user.successful) {
                return 'Your details were updated successfully.'
                
            } else {
                throw new AuthError('Unable to update your details at this time.');
            }

        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to update your details at this time.');
            }

        }
        
    }


    const isGoogleAccount = () => {
        const token = localStorage.getItem('idToken');
        return !!(token && verifyToken(token) && hasGoogleIdentity(token));
    }

    const updateCognitoAttributes = async(u) => {
        const data = {
            Email: u.email,
            GivenName: u.firstName,
            FamilyName: u.lastName,
            MobilePhoneNumber: u.phone,
            CountryCode: u.countryCode
        };
        try {
            const token = localStorage.getItem('idToken');

            if (token && verifyToken(token)) {
                const axiosAuthWithToken = axios.create({
                    baseURL: config.awsAuth.baseUrl,
                    headers: {
                        [config.awsAuth.apiKeyPrefix]: config.awsAuth.xApiKey,
                        authorization: token
                    }
                });
                const queryString = new URLSearchParams(data).toString();
                const response = await axiosAuthWithToken.patch(`/updateuser?${queryString}`);
                if (response.data.successful) {
                    dispatch({
                        type: UPDATE_COGNITO_ATTRIBUTES
                    });
                } else {
                    throw new AuthError(response.data.responseMessage);
                }
            } else {
                throw new AuthError('Not a valid token.');
            }
        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to update cognito attributes.');
            }
        }
    }

    const resendVerification = async (email) => {
        const data = {
          Username: email
        };
        try {
            const response = await axiosAuth.post('/resendverification', data);
            if (response.data.successful) {
                dispatch({
                    type: SEND_VERIFY
                });
            } else {
                throw new AuthError(response.data.responseMessage);
            }
        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to send verification email at this time.');
            }
        }
        
    }

    const sendReset = async (email) => {
        const data = {
          Username: email
        };
        try {
            const response = await axiosAuth.post('/passwordreset', data);
            if (response.data.successful) {
                dispatch({
                    type: SEND_RESET
                });
            } else {
                throw new AuthError(response.data.responseMessage);
            }
        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to send reset password instructions at this time.');
            }
        }
        
    }

    const doReset = async (email, code, password) => {
        const data = {
          Username: email,
          ConfirmationCode : code,
          Password : password

        };

        //return;
        try {
            const response = await axiosAuth.post('/confirmpasswordreset', data);
            if (response.data.successful) {
                dispatch({
                    type: DO_RESET
                });
            } else {
                dispatch({
                    type: FAIL_RESET
                });
                throw new AuthError(response.data.responseMessage);
            }
        } catch (error) {
            if (error.response) {
                throw new AuthError(error.response.data.responseMessage);
            } else {
                throw new AuthError('Unable to reset password at this time.');
            }
        }
        
    }

    const clearReset = () => {
        dispatch({
            type: CLEAR_RESET
        });
    }

    const clearVerify = () => {
        dispatch({
            type: CLEAR_VERIFY
        });
    }

    const checkPhoneExists  = async (phone) => {
        const response = await axiosAuth.get(`/ismobilenumberinuse?mobilePhoneNumber=${encodeURIComponent(phone)}`);
        if (response.data.successful) {
            if (response.data.results) {
                throw new AuthError('There is already an account associated with that mobile phone number');
            }
        }
    }

    const checkEmailExists = async (email) => {
        const data = {
          Username: email
        };

        const response = await axiosAuth.post('/checkuserexists', data);
        if (response.data.successful) {
            if (response.data.results === 'EXTERNAL_PROVIDER'){
                throw new AuthError('A google account is associated with this email address.');
            } else if (response.data.results === 'CONFIRMED') {
                console.log('confirmed');
                throw new AuthError('An account already exists with this email address.');
            }
          // const users = response.data.results?.users;
          // if (users.length) {
          //   const status = users?.[0]?.userStatus?.value;
          //   if (status === 'EXTERNAL_PROVIDER') {
          //     throw new AuthError('There is a google account associated with that email address.');
          //   }
          //   throw new AuthError('There is already an account associated with that email address.');
          // }
        } 
    }

    const init = async () => {
        try {
            const idToken = window.localStorage.getItem('idToken');

             if (!idToken || !verifyToken(idToken)) {
                 await refreshTokens();
             }


            if (idToken && verifyToken(idToken)) {

                const cognitoSub = getCognitoSub(idToken);

                const user = await UsersService.getApiUsersGetUserBySub();
                
                if (user.successful) {

                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            cognitoSub : user.results.cognitoSub,
                            isRegistered : true,
                            user : user.results
                        }
                    });

                    
                } else {
                    // throw new Error('No user data');
                    const payload = {
                      cognitoSub : cognitoSub,
                      isRegistered : false,
                      isLoggedIn: true,
                      user : {
                          email : getEmail(idToken),
                          cognitoSub : cognitoSub,
                          firstName : getFirstName(idToken),
                          lastName : getLastName(idToken),
                          countryCode: getCountryCode(idToken),
                          phone: getPhone(idToken),
                          isGoogleAccount: hasGoogleIdentity(idToken)
                      }
                  };
                  dispatch({
                      type: ACCOUNT_INITIALIZE,
                      payload
                  });
                }

                
            } else {
                
                throw new Error('Could not refresh tokens');
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isLoggedIn: false,
                    cognitoSub : null,
                    isRegistered : null,
                    user : null
                }
            });
        }
    };


    useEffect(() => {
        init();
    }, []);

    // useEffect(() => {
    //     const connection = new HubConnectionBuilder()
    //         .withUrl(`${config.aws.signalr}`, {
    //             headers: {"x-api-key": config.aws.xApiKey},
    //             withCredentials: false
    //         })
    //         .withAutomaticReconnect()
    //         .build();
    //
    //     connection.start()
    //         .then(result => {
    //             console.log('Connected!');
    //
    //             connection.on('ReceiveMessage', message => {
    //                 console.log(message);
    //             });
    //         })
    //         .catch(e => console.log('Connection failed: ', e));
    // }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return  (
        <AuthContext.Provider value={{ ...state, login, logout, signup, register, resendVerification, sendReset, doReset, clearReset, clearVerify, checkEmailExists, checkPhoneExists, updatePatient, updateCognitoAttributes, isGoogleAccount, init }}>
            {children}
            
        </AuthContext.Provider>
    );
};

export default AuthContext;
